import AbstractStorage from '@/lib/utils/storage/abstract-storage';
import LocalStorage from '@/lib/utils/storage/local-storage';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

/**
 * The default storage
 *
 * @constant {LocalStorage} defaultStorage
 */
const defaultStorage = new LocalStorage();
/**
 * The default referral source key
 *
 * @constant {string} defaultReferralSourceKey
 */
const defaultReferralSourceKey = 'utm_campaign';
/**
 * The default referral storage key
 *
 * @constant {string} defaultReferralStorageKey
 */
const defaultReferralStorageKey = 'shelter_referral';
/**
 * The campaign prefix for a shelter referral
 *
 * @constant {string} campaignPrefix
 */
export const campaignPrefix = 'lost-lostfound-shelter-';

/**
 * The use referral props
 *
 * @interface IUseReferralProps
 */
export interface IUseReferralProps {
  /**
   * The storage
   *
   * @memberof IUseReferralProps
   * @default defaultStorage
   * @member {AbstractStorage} [storage]
   */
  storage?: AbstractStorage;
  /**
   * The referral storage key
   *
   * @memberof IUseReferralProps
   * @default defaultReferralStorageKey
   * @member {string} [referralStorageKey]
   */
  referralStorageKey?: string;
  /**
   * The referral source key
   *
   * @memberof IUseReferralProps
   * @default defaultReferralSourceKey
   * @member {string} [referralSourceKey]
   */
  referralSourceKey?: string;
}

/**
 * The use referral interface
 *
 * @interface IUseReferral
 */
export interface IUseReferral {
  /**
   * Sets the referral to the storage
   *
   * @memberof IUseReferral
   * @member {Function} setReferral
   * @returns {void}
   */
  setReferral: () => void;

  /**
   * Gets the referral from the storage
   *
   * @memberof IUseReferral
   * @member {Function} getReferral
   * @returns {string | null} - The referral
   */
  getReferral: () => number | null;
}

/**
 * A hook to interact with the referral
 *
 * @param {IUseReferralProps} props - The referral props
 * @returns {IUseReferral} The referral hook
 */
const useReferral = ({
  storage = defaultStorage,
  referralStorageKey = defaultReferralStorageKey,
  referralSourceKey = defaultReferralSourceKey,
}: IUseReferralProps): IUseReferral => {
  /**
   * The search params
   *
   * @constant {ReadonlyURLSearchParams} searchParams
   */
  const searchParams: ReadonlyURLSearchParams = useSearchParams();
  /**
   * A callback to set the referral from the search params to the storage
   *
   * @returns {void}
   */
  const setReferral = useCallback(() => {
    // Only proceed if search params exists
    if (!searchParams) {
      return;
    }

    /**
     * The referral from the search params
     *
     * @constant {string | null} referral
     */
    const referral = searchParams.get(referralSourceKey);

    if (!referral) {
      return;
    }
    /**
     * The awo id from the referral
     *
     * @constant {string} awoId
     */
    const awoId = getAwoIdFromUtmCampaign(referral);

    if (awoId) {
      // Set the referral to the storage
      storage.set(referralStorageKey, awoId);
    }
  }, [searchParams, referralSourceKey, referralStorageKey, storage]);

  /**
   * A callback to get the referral
   *
   * @returns {string | null} - The referral
   */
  const getReferral = useCallback(() => {
    /**
     * The referral from storage
     *
     * @constant {string | object | null}
     */
    const referral = storage.get(referralStorageKey);

    if (
      referral &&
      (typeof referral === 'string' || typeof referral === 'number')
    ) {
      return Number(referral);
    }

    return null;
  }, [referralStorageKey, storage]);

  return {
    setReferral,
    getReferral,
  };
};

export default useReferral;

/**
 * Get the awo id from the utm campaign
 *
 * @param {string} utmCampaign - The utm campaign
 * @returns {string | null} - The awo id
 */
export const getAwoIdFromUtmCampaign = (utmCampaign: string): string | null => {
  /**
   * The awo id part of the referral
   *
   * @constant {string}
   */
  const awoId = utmCampaign.split(campaignPrefix).at(-1);

  if (
    utmCampaign.includes(campaignPrefix) &&
    awoId &&
    !isNaN(Number(awoId)) &&
    awoId.trim() !== ''
  ) {
    return awoId;
  }

  console.error('invalid referral');

  return null;
};
