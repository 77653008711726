import { createContext, ReactNode, useContext, useEffect } from 'react';
import useReferral, { IUseReferral, IUseReferralProps } from './useReferral';

/**
 * The default context
 *
 * @constant {IUseReferral}
 */
export const defaultContext: IUseReferral = {
  /** The default set referral function */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setReferral: () => {},
  /**
   * The default get referral function
   *
   * @returns {null} - The default referral
   */
  getReferral: (): null => null,
};

/**
 * The shelter referral context
 *
 * @constant {React.Context<IUseReferral>} ShelterReferralContext
 */
const ShelterReferralContext = createContext<IUseReferral>(defaultContext);

/**
 * The default use shelter referral
 *
 * @augments IUseReferralProps
 * @interface IShelterReferralProviderProps
 */
export interface IShelterReferralProviderProps extends IUseReferralProps {
  /**
   * The children of the ShelterReferralProvider
   *
   * @memberof IShelterReferralProviderProps
   * @member {ReactNode} children
   */
  children: ReactNode;
}

/**
 * The ShelterReferralProvider
 *
 * @param {IShelterReferralProviderProps} props - The ShelterReferralProvider
 *   props
 * @returns {Element} - The ShelterReferralProvider
 */
export function ShelterReferralProvider({
  children,
  ...rest
}: IShelterReferralProviderProps): JSX.Element {
  /**
   * The referral hook
   *
   * @constant {IUseReferral} referral
   */
  const referral = useReferral(rest);

  /** Hook to set the referral on mount */
  useEffect(() => {
    referral.setReferral();
  }, [referral]);

  return (
    <ShelterReferralContext.Provider value={referral}>
      {children}
    </ShelterReferralContext.Provider>
  );
}

/**
 * A hook to access the ShelterReferralContext
 *
 * @returns {IUseReferral} - The shelter referral context
 */
export const useShelterReferral = (): IUseReferral =>
  useContext(ShelterReferralContext);
